import React, { useCallback, useEffect, useRef } from "react";
import { createNoise3D } from "simplex-noise";

// Define the threshold for mobile devices in pixels
const MOBILE_THRESHOLD = 768;

// Interface defining the parameters that control our noise animation
interface DNA {
  dna1: number; // Controls X-scale of first noise layer
  dna2: number; // Controls Y-scale of first noise layer
  dna3: number; // Controls X-scale of second noise layer
  dna4: number; // Controls Y-scale of second noise layer
  dna5: number; // Controls amplitude of first noise layer
  dna6: number; // Controls amplitude of second noise layer
  dna7: number; // Offset for first noise layer
  dna8: number; // Offset for second noise layer
  dna9: number; // Controls time scale for first noise layer
  dna10: number; // Controls time scale for second noise layer
  dna11: number; // Controls red channel intensity
  dna12: number; // Controls green channel intensity
  dna13: number; // Controls blue channel intensity
}

// DNA configuration that defines the visual characteristics of our noise
const DNA: DNA = {
  dna1: 233,
  dna2: 269,
  dna3: 571,
  dna4: 771,
  dna5: 0.6,
  dna6: 49,
  dna7: 0.23,
  dna8: 0.83,
  dna9: 333,
  dna10: 775,
  dna11: 218,
  dna12: 39,
  dna13: 249,
};

// Define types for messages passed between main thread and worker
interface WorkerMessage {
  width: number;
  height: number;
  noiseValues: Float32Array;
  dna: DNA;
  type?: "visibility";
  isVisible?: boolean;
}

interface WorkerResponse {
  buffer: ArrayBuffer;
}

// Web Worker code that handles pixel processing
const workerCode = `
  let isProcessing = false;
  let isActive = true;
  let cachedWidth = 0;
  let cachedHeight = 0;
  let cachedBuffer = null;
  
  self.onmessage = function(e) {
    if (e.data.type === 'visibility') {
      isActive = e.data.isVisible;
      return;
    }
    
    if (!isActive || isProcessing) return;
    isProcessing = true;
    
    const { width, height, noiseValues, dna } = e.data;
    
    // Only create a new buffer if dimensions change
    if (width !== cachedWidth || height !== cachedHeight || !cachedBuffer) {
      cachedWidth = width;
      cachedHeight = height;
      cachedBuffer = new Uint8ClampedArray(width * height * 4);
    }
    
    // Process data in larger chunks for better performance
    const CHUNK_SIZE = 100;
    let currentRow = 0;
    
    function processChunk() {
      const endRow = Math.min(currentRow + CHUNK_SIZE, height);
      
      for (let y = currentRow; y < endRow; y++) {
        const rowOffset = y * width;
        for (let x = 0; x < width; x++) {
          const i = rowOffset + x;
          const r = noiseValues[i * 2];
          const g = noiseValues[i * 2 + 1];
          const data1 = (r || g) * dna.dna11;
          const data3 = dna.dna13;
          const bufferIndex = i * 4;
          
          cachedBuffer[bufferIndex + 0] = data1;
          cachedBuffer[bufferIndex + 1] = r * g * dna.dna12;
          cachedBuffer[bufferIndex + 2] = data3;
          cachedBuffer[bufferIndex + 3] = 255;
        }
      }
      
      currentRow = endRow;
      
      if (currentRow < height) {
        setTimeout(processChunk, 0);
      } else {
        self.postMessage({ 
          buffer: cachedBuffer.buffer 
        }, [cachedBuffer.buffer]);
        
        // Create new buffer for next frame
        cachedBuffer = new Uint8ClampedArray(width * height * 4);
        isProcessing = false;
      }
    }
    
    processChunk();
  };
`;

export default function Awoken(): JSX.Element {
  // Refs for managing component state and resources
  const isMountedRef = useRef<boolean>(false);
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const workerRef = useRef<Worker | null>(null);
  const workerBlobUrlRef = useRef<string | null>(null);
  const animationFrameRef = useRef<number | null>(null);
  const noiseValuesRef = useRef<Float32Array | null>(null);
  const isVisibleRef = useRef<boolean>(true);
  const tRef = useRef<number>(0);

  // Cleanup function to properly dispose of resources
  const cleanup = useCallback(() => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
      animationFrameRef.current = null;
    }

    if (workerRef.current) {
      workerRef.current.terminate();
      workerRef.current = null;
    }

    if (workerBlobUrlRef.current) {
      URL.revokeObjectURL(workerBlobUrlRef.current);
      workerBlobUrlRef.current = null;
    }

    if (noiseValuesRef.current) {
      noiseValuesRef.current = null;
    }
  }, []);

  useEffect(() => {
    isMountedRef.current = true;
    const canvas = canvasRef.current;
    const container = containerRef.current;
    if (!canvas || !container) return;

    const ctx = canvas.getContext("2d", {
      alpha: false,
      desynchronized: true,
    });
    if (!ctx) return;

    // Handle canvas resizing with proper dimension validation
    const resizeCanvas = () => {
      if (!isMountedRef.current) return;

      const { width, height } = container.getBoundingClientRect();
      const isMobile = window.innerWidth <= MOBILE_THRESHOLD;
      const scale = isMobile ? 0.25 : 0.5;
      const newWidth = Math.max(1, Math.floor(width * scale));
      const newHeight = Math.max(1, Math.floor(height * scale));

      canvas.width = newWidth;
      canvas.height = newHeight;
      canvas.style.width = `${width}px`;
      canvas.style.height = `${height}px`;

      if (isMountedRef.current) {
        noiseValuesRef.current = new Float32Array(
          newWidth * newHeight * 2
        );
      }
    };

    // Initialize Web Worker with proper URL handling
    try {
      const blob = new Blob([workerCode], {
        type: "application/javascript",
      });
      workerBlobUrlRef.current = URL.createObjectURL(blob);
      workerRef.current = new Worker(workerBlobUrlRef.current);

      workerRef.current.onmessage = (
        e: MessageEvent<WorkerResponse>
      ) => {
        if (!isMountedRef.current || !canvas) return;

        try {
          const imageData = new ImageData(
            new Uint8ClampedArray(e.data.buffer),
            canvas.width,
            canvas.height
          );
          ctx.putImageData(imageData, 0, 0);
        } catch (error) {
          console.error("Error rendering frame:", error);
        }
      };
    } catch (error) {
      console.error("Failed to initialize worker:", error);
      return;
    }

    // Animation timing variables
    let lastTime = 0;
    const noise3D = createNoise3D();
    const isMobile = window.innerWidth <= MOBILE_THRESHOLD;
    const targetFPS = isMobile ? 24 : 30;
    const frameInterval = 1000 / targetFPS;

    // Main animation loop
    const animate = (currentTime: number) => {
      if (!isMountedRef.current) return;

      if (isVisibleRef.current) {
        animationFrameRef.current =
          requestAnimationFrame(animate);
      }

      if (currentTime - lastTime < frameInterval) return;
      lastTime = currentTime;

      const { width, height } = canvas;
      const noiseValues = noiseValuesRef.current;

      if (!noiseValues || !isVisibleRef.current) return;

      try {
        // Generate noise values efficiently using row-based calculation
        const t = tRef.current;
        for (let y = 0; y < height; y++) {
          const rowOffset = y * width;
          for (let x = 0; x < width; x++) {
            const i = (rowOffset + x) * 2;
            noiseValues[i] =
              noise3D(x / DNA.dna1, y / DNA.dna2, t / DNA.dna9) *
                DNA.dna5 +
              DNA.dna7;
            noiseValues[i + 1] =
              noise3D(x / DNA.dna3, y / DNA.dna4, t / DNA.dna10) *
                DNA.dna6 +
              DNA.dna8;
          }
        }

        if (workerRef.current && isMountedRef.current) {
          workerRef.current.postMessage(
            {
              width,
              height,
              noiseValues,
              dna: DNA,
            },
            [noiseValues.buffer]
          );

          noiseValuesRef.current = new Float32Array(
            width * height * 2
          );
        }
      } catch (error) {
        console.error("Error generating noise:", error);
        return;
      }

      tRef.current++;
    };

    // Initialize the animation system
    resizeCanvas();
    const debouncedResize = debounce(resizeCanvas, 250);
    window.addEventListener("resize", debouncedResize);

    // Handle tab visibility changes
    const handleVisibilityChange = () => {
      const isVisible = document.visibilityState === "visible";
      isVisibleRef.current = isVisible;

      workerRef.current?.postMessage({
        type: "visibility",
        isVisible,
      });

      if (isVisible) {
        lastTime = performance.now();
        animationFrameRef.current =
          requestAnimationFrame(animate);
      } else if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
        animationFrameRef.current = null;
      }
    };

    document.addEventListener(
      "visibilitychange",
      handleVisibilityChange
    );
    animationFrameRef.current = requestAnimationFrame(animate);

    // Cleanup function
    return () => {
      isMountedRef.current = false;
      document.removeEventListener(
        "visibilitychange",
        handleVisibilityChange
      );
      window.removeEventListener("resize", debouncedResize);
      cleanup();
    };
  }, [cleanup]);

  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <canvas
        ref={canvasRef}
        style={{
          width: "100%",
          height: "100%",
          display: "block",
        }}
      />
    </div>
  );
}

// Utility function for debouncing resize events
function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait: number
): (...args: Parameters<T>) => void {
  let timeout: NodeJS.Timeout | null = null;

  return function executedFunction(...args: Parameters<T>): void {
    const later = () => {
      timeout = null;
      func(...args);
    };

    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(later, wait);
  };
}
