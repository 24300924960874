import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import { useInView } from "react-intersection-observer";
import {
  useInfiniteQuery,
  useQueryClient,
  useQuery,
} from "@tanstack/react-query";
import {
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  CollectionFilter,
  createLikeOnAsset,
  deleteLikeOnAsset,
  fetchCollectionLikes,
  fetchCollectionListings,
  fetchUserById,
  getAssetsFromCollectionFromBackendTest,
  getCollectionFilterFromBackend,
} from "helpers/helpers";
import { AssetData, Collection } from "helpers/types";
import CardNFT from "components/CardNFT";
import LoadingSpinner from "components/LoadingSpinner";
import { doGet } from "helpers/api_helper";
import { ListingResponse } from "helpers/helpers";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Tooltip } from "flowbite-react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import VerifyIcon from "components/VerifyIcon";
import CollectionOnchain from "components/CollectionOnchain";
import { Badge, Button } from "@mantine/core";
import NcImage from "shared/NcImage/NcImage";
import CollectionTags from "components/CollectionTags";
import NftMoreDropdown from "components/NftMoreDropdown";
import { useAppSelector } from "app/hooks";
import { selectUser } from "app/auth/login/reducer";
import { useEditor } from "@tiptap/react";
import Highlight from "@tiptap/extension-highlight";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import TextAlign from "@tiptap/extension-text-align";
import Superscript from "@tiptap/extension-superscript";
import SubScript from "@tiptap/extension-subscript";
import Image from "@tiptap/extension-image";
import { RichTextEditor, Link } from "@mantine/tiptap";
import { Transition } from "@headlessui/react";
import ButtonOutline from "shared/Button/ButtonOutline";
import { ChevronDownIcon } from "@heroicons/react/outline";
import TabFilters from "components/TabFilters";
import CardNFTSkeleton from "components/CardNFTSkeleton";
import { debounce } from "utils/debounce";
import { Filter } from "../Admin/PageCollectionAdminWip";
import SideFilters from "components/CollectionFilters";
import logoImg from "images/logo.svg";
import { fetchSignedUrl } from "utils/fetchSignedUrl";

const extensions = [
  StarterKit,
  Underline,
  Link,
  Superscript,
  SubScript,
  Image,
  Highlight,
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
];

const PageCollection = () => {
  const { ref, inView } = useInView();
  const { path } = useParams();
  const queryClient = useQueryClient();
  const userDetails = useAppSelector(selectUser);
  const navigate = useNavigate();
  const [showFullDescription, setShowFullDescription] =
    React.useState(false);
  const [openTab, setOpenTab] = React.useState(1);

  const [isOpen, setIsOpen] = React.useState(false);

  const location = useLocation();
  // string filter
  const searchParams = new URLSearchParams(location.search);
  const searchQuery = searchParams.get("search") || "";
  const [searchInput, setSearchInput] =
    React.useState(searchQuery);

  // price filter
  const priceRangeFromUrl = searchParams
    .get("priceRange")
    ?.split(",")
    .map(Number);
  const isTwoElementArray = (
    arr?: (number | undefined)[]
  ): arr is [number, number?] => {
    return (
      arr !== undefined && Array.isArray(arr) && arr.length >= 1
    );
  };
  let priceRange: [number, number?] = [0, Infinity];
  if (
    priceRangeFromUrl !== undefined &&
    isTwoElementArray(priceRangeFromUrl)
  ) {
    if (priceRangeFromUrl.length === 2) {
      priceRange = priceRangeFromUrl as [number, number];
    } else {
      priceRange = [priceRangeFromUrl[0], Infinity];
    }
  }

  // sort data
  const sortOrder = searchParams.get("sortBy") || "price:asc";
  const [sortOrderChanged, setSortOrderChanged] =
    React.useState(false);

  // metadata filter
  const parseMetadataFiltersFromURL = (
    searchParams: URLSearchParams
  ) => {
    const metadataFilters: CollectionFilter = { metadata: {} };

    for (const [key, value] of searchParams.entries()) {
      if (key.startsWith("metadata.range.")) {
        const path = key
          .replace("metadata.range.", "")
          .replace(/\./g, ".");
        const [start, end] = value.split(",");
        console.log(
          `Parsing range filter from URL for ${path}:`,
          start,
          end
        );
        metadataFilters.metadata[path] = {
          type: "number",
          path,
          numberFilter: {
            range: { start, end },
            selection: { start, end }, // Use the parsed values directly
          },
        };
      } else if (key.startsWith("metadata.string.")) {
        const path = key
          .replace("metadata.string.", "")
          .replace(/\./g, ".");
        const options = value.split(",");
        metadataFilters.metadata[path] = {
          type: "string",
          path,
          stringFilter: {
            options,
            selection: options,
          },
        };
      }
    }
    console.log(
      "Parsed metadata filters:",
      JSON.stringify(metadataFilters, null, 2)
    );
    return metadataFilters;
  };

  // Fetch collection data including the policy ID
  const {
    isPending: collectionPending,
    error: collectionError,
    data: collection,
  } = useQuery({
    queryKey: ["collection", path],
    queryFn: async () => {
      const response = await doGet<Collection>(
        `/api/collection/path/${path}`,
        {}
      );
      return response.data;
    },
  });
  console.log("isPending:", collectionPending);

  // fetch metadata filters for collection
  const {
    data: filterSchema,
    isLoading: filterSchemaLoading,
    error: filterSchemaError,
  } = useQuery({
    queryKey: ["filterSchema", collection?.policy],
    queryFn: async () => {
      if (!collection?.policy) return {};
      const schema = await getCollectionFilterFromBackend(
        collection.policy
      );
      return { metadata: JSON.parse(JSON.stringify(schema)) };
    },
    enabled: !!collection?.policy,
  });

  const [selectedFilters, setSelectedFilters] =
    React.useState<CollectionFilter>({
      metadata: filterSchema?.metadata || {},
    });

  // fetch asset data from collection policy id
  const {
    status,
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<AssetData[], Error>({
    queryKey: [
      "assets",
      collection?.policy,
      searchQuery,
      priceRange,
      sortOrder,
      sortOrderChanged,
      parseMetadataFiltersFromURL(
        new URLSearchParams(location.search)
      ),
    ],
    queryFn: async ({ pageParam = 0 }) => {
      if (!collection) {
        return Promise.resolve([]); // Return an empty array if collection data is not available yet
      }
      const policyId =
        collection?.policy ||
        "95c248e17f0fc35be4d2a7d186a84cdcda5b99d7ad2799ebe98a9865";

      return getAssetsFromCollectionFromBackendTest(
        policyId,
        pageParam as number,
        12,
        sortOrder,
        priceRange,
        searchQuery,
        parseMetadataFiltersFromURL(
          new URLSearchParams(location.search)
        ).metadata
      );
    },
    getNextPageParam: (lastPage, allPages) => {
      return lastPage.length > 0 ? allPages.length + 1 : null;
    },
    initialPageParam: 0,
    enabled: !!collection, // Enable the query only when collection data is available
    refetchOnMount: true,
  });

  // fetch pricing data for assets
  const {
    isPending: pricingPending,
    error: pricingError,
    data: pricingData,
  } = useQuery<ListingResponse | undefined, Error>({
    queryKey: ["pricing", collection?.policy],
    queryFn: async () => {
      if (!collection) {
        return Promise.resolve({});
      }
      return fetchCollectionListings(collection.policy);
    },
    enabled: !!collection,
  });

  // fetch liked data for assets
  const {
    status: likesStatus,
    data: likedMap,
    error: likesError,
  } = useQuery({
    queryKey: ["likedMap", collection?.policy],
    queryFn: async () => {
      if (!collection || !collection.policy) return {};
      return fetchCollectionLikes(collection.policy);
    },
    enabled: !!collection?.policy,
  });

  const handleOnIsLikedChange = async (
    isLiked: boolean,
    assetId: string
  ) => {
    console.log(isLiked, assetId);
    if (isLiked) {
      await createLikeOnAsset(collection?.policy ?? "", assetId);
      queryClient.setQueryData(
        ["likedMap", collection?.policy],
        (prevLikedMap: any) => ({
          ...prevLikedMap,
          [assetId]: true,
        })
      );
      // Update the assets cache
      queryClient.setQueryData(
        [
          "assets",
          collection?.policy,
          searchQuery,
          priceRange,
          sortOrder,
          sortOrderChanged,
          parseMetadataFiltersFromURL(
            new URLSearchParams(location.search)
          ),
        ],
        (oldData: any) => {
          return {
            ...oldData,
            pages: oldData.pages.map((page: any) =>
              page.map((asset: any) =>
                asset._id === assetId
                  ? { ...asset, likes: (asset.likes || 0) + 1 }
                  : asset
              )
            ),
          };
        }
      );
    } else {
      await deleteLikeOnAsset(collection?.policy ?? "", assetId);
      queryClient.setQueryData(
        ["likedMap", collection?.policy],
        (prevLikedMap: any) => ({
          ...prevLikedMap,
          [assetId]: false,
        })
      );
      // Update the assets cache
      queryClient.setQueryData(
        [
          "assets",
          collection?.policy,
          searchQuery,
          priceRange,
          sortOrder,
          sortOrderChanged,
          parseMetadataFiltersFromURL(
            new URLSearchParams(location.search)
          ),
        ],
        (oldData: any) => {
          return {
            ...oldData,
            pages: oldData.pages.map((page: any) =>
              page.map((asset: any) =>
                asset._id === assetId
                  ? {
                      ...asset,
                      likes: Math.max((asset.likes || 0) - 1, 0),
                    }
                  : asset
              )
            ),
          };
        }
      );
    }
  };

  const editor = useEditor(
    {
      editable: false,
      content: collection?.details,
      extensions,
    },
    [collection?.details]
  );

  React.useEffect(() => {
    if (inView && !isFetchingNextPage && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, isFetchingNextPage, hasNextPage, fetchNextPage]);

  const handleArtistClick = async (ownerId: string) => {
    if (ownerId === null || ownerId === "unclaimed") {
      return;
    }
    try {
      const user = await fetchUserById(ownerId);
      if (user) {
        const name = user.name;
        console.log("Name:", name);
        navigate(`/${name}`);
      } else {
        console.error("User data is null.");
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(collection?.policy ?? "");
  };

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const handleTabClick = (tabNumber: number) => {
    setOpenTab(tabNumber);
    if (containerRef.current) {
      containerRef.current.scrollIntoView({});
    }
  };

  const debouncedHandleSearchQueryChange = React.useCallback(
    debounce((query: string) => {
      const searchParams = new URLSearchParams(location.search);
      searchParams.set("search", query);
      navigate(`?${searchParams.toString()}`);
    }, 300),
    [location.search, navigate]
  );

  const handleSearchQueryChange = (query: string) => {
    setSearchInput(query);
    debouncedHandleSearchQueryChange(query);
  };

  const handlePriceFilterChange = (
    newPriceRange: [number, number]
  ) => {
    const searchParams = new URLSearchParams();
    searchParams.set("priceRange", newPriceRange.join(","));
    navigate(`?${searchParams.toString()}`);
  };

  const handleSortOrderChange = (newSortOrder: string) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("sortBy", newSortOrder);
    navigate(`?${searchParams.toString()}`);
    setSortOrderChanged(!sortOrderChanged);
  };

  const updateURLSearchParams = (
    selectedFilters: CollectionFilter,
    searchParams: URLSearchParams
  ) => {
    for (const key in selectedFilters.metadata) {
      const filter = selectedFilters.metadata[key];
      if (filter.numberFilter?.selection) {
        const rangeKey = [
          "metadata",
          "range",
          ...filter.path.split("."),
        ].join(".");
        searchParams.set(
          rangeKey,
          `${filter.numberFilter.selection.start},${filter.numberFilter.selection.end}`
        );
      } else if (filter.stringFilter?.selection) {
        const stringKey = [
          "metadata",
          "string",
          ...filter.path.split("."),
        ].join(".");
        searchParams.set(
          stringKey,
          filter.stringFilter.selection.join(",")
        );
      }
    }

    navigate(`?${searchParams.toString()}`, { replace: true });
  };

  const handleMetadataFilterChange = (
    name: string,
    filter: Filter
  ) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };

      if (
        filter.stringFilter?.selection &&
        filter.stringFilter.selection.length === 0
      ) {
        // If no values are selected for the string filter, remove the filter from selectedFilters
        delete updatedFilters.metadata[name];
      } else {
        // Otherwise, update the filter in selectedFilters
        updatedFilters.metadata[name] = {
          ...prevFilters.metadata[name],
          ...filter,
          path: filter.path || name,
        };
      }

      // Preserve existing search parameters
      const searchParams = new URLSearchParams();
      const existingParams = new URLSearchParams(location.search);
      for (const [key, value] of existingParams.entries()) {
        if (
          !["metadata.string", "metadata.range"].some((prefix) =>
            key.startsWith(prefix)
          )
        ) {
          searchParams.set(key, value);
        }
      }
      if (containerRef.current) {
        containerRef.current.scrollIntoView({});
      }
      updateURLSearchParams(updatedFilters, searchParams);
      return updatedFilters;
    });
  };

  const applyFilters = () => {
    // queryKey for useInfiniteQuery includes the selected filters
    // to trigger a refetch
  };

  const resetFilters = () => {
    const emptyFilter: CollectionFilter = { metadata: {} };
    const searchParams = new URLSearchParams();

    // Preserve existing search parameters that are not related to metadata filters
    const existingParams = new URLSearchParams(location.search);
    for (const [key, value] of existingParams.entries()) {
      if (
        !["metadata.string", "metadata.range"].some((prefix) =>
          key.startsWith(prefix)
        )
      ) {
        searchParams.set(key, value);
      }
    }

    setSelectedFilters(emptyFilter);
    updateURLSearchParams(emptyFilter, searchParams);
  };

  const containerRef = useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollIntoView({});
    }
  }, [sortOrder]);

  const [bannerUrl, setBannerUrl] = React.useState("");

  React.useEffect(() => {
    if (collection?.bannerpath) {
      fetchSignedUrl(collection.bannerpath)
        .then((url) => setBannerUrl(url))
        .catch((error) =>
          console.error("Error fetching banner URL:", error)
        );
    }
  }, [collection?.bannerpath]);

  if (collectionPending) return <LoadingSpinner />;
  if (collectionError)
    return (
      <div>An error has occurred: {collectionError.message}</div>
    );

  return (
    <div
      className={`nc-PageCollection`}
      data-nc-id="PageCollection"
    >
      <Helmet>
        <title>
          Cardano Art - {collection?.name ?? "Collection"}
        </title>
        <meta
          name="description"
          content={`${
            collection?.description?.slice(0, 170) ?? ""
          }`}
        />
        <meta
          property="og:url"
          content={`https://cardano.art/collection/${
            collection?.path ?? ""
          }`}
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Cardano Art - ${
            collection?.name ?? "Collection"
          }`}
        />
        <meta
          property="og:description"
          content={`${
            collection?.description?.slice(0, 170) ?? ""
          }`}
        />
        <meta
          property="og:image"
          content={bannerUrl || logoImg}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="cardano.art" />
        <meta
          property="twitter:url"
          content={`https://cardano.art/collection/${
            collection?.path ?? ""
          }`}
        />
        <meta
          name="twitter:title"
          content={`Cardano Art - ${
            collection?.name ?? "Collection"
          }`}
        />
        <meta
          name="twitter:description"
          content={`${
            collection?.description?.slice(0, 170) ?? ""
          }`}
        />
        <meta
          name="twitter:image"
          content={bannerUrl || logoImg}
        />
      </Helmet>
      {/* HEADER */}
      <div className="w-full">
        <div className="relative w-full h-40 md:h-60 2xl:h-72">
          <NcImage
            containerClassName="absolute inset-0"
            src={collection.bannerpath}
            className="object-cover w-full h-full"
          />
        </div>
        <div className="relative container -mt-14 lg:-mt-20">
          <div className=" bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row items-start">
            <div className="flex flex-col sm:flex-row md:block sm:items-start sm:justify-between">
              <div className="top-0 left-0 flex flex-wrap justify-start pb-5 cursor-pointer">
                <CollectionTags
                  tags={collection.tags}
                  className="hover:bg-gray-700 dark:hover:bg-slate-600 dark:hover:text-white"
                  clickable={true}
                />
              </div>
              <div className="w-40 sm:w-48 md:w-56 xl:w-60">
                <NcImage
                  src={collection.avatarpath}
                  containerClassName="aspect-w-1 aspect-h-1 rounded-3xl overflow-hidden"
                />
              </div>

              <div className="mt-4 flex items-center sm:justify-center space-x-3">
                <div className="flex space-x-1.5 text-neutral-700 dark:text-neutral-300">
                  {collection.discordpath !== "" ? (
                    <a
                      href={`https://${collection.discordpath}`}
                      target="_blank"
                      className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
                      rel="noreferrer"
                    >
                      <i className="text-base sm:text-xl lab la-discord"></i>
                    </a>
                  ) : null}
                  {collection.twitterpath !== "" ? (
                    <a
                      href={`https://${collection.twitterpath}`}
                      target="_blank"
                      className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
                      rel="noreferrer"
                    >
                      <i className="text-base sm:text-xl lab la-twitter"></i>
                    </a>
                  ) : null}
                </div>
                <div className="h-5 border-l border-neutral-200 dark:border-neutral-700"></div>
                <div className="flex space-x-1.5">
                  {collection.websitepath !== "" ? (
                    <a
                      href={`https://${collection.websitepath}`}
                      target="_blank"
                      className="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
                      rel="noreferrer"
                    >
                      <i className="text-base sm:text-xl las la-link"></i>
                    </a>
                  ) : null}
                  <NftMoreDropdown
                    actions={[
                      {
                        id: "report",
                        name: "Report abuse",
                        icon: "las la-flag",
                      },
                      {
                        id: "refresh",
                        name: "Sync",
                        icon: "las la-sync",
                      },
                      {
                        id: "edit",
                        name: "Edit Collection",
                        icon: "las la-pen",
                      },
                      {
                        id: "admin",
                        name: "Admin Edit",
                        icon: "las la-user-cog",
                      },
                    ]}
                    containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
                  />
                </div>
              </div>
            </div>
            <div className="mt-5 md:mt-0 md:ml-8 xl:ml-14 flex-grow flex flex-col">
              <div className="max-w-screen-lg md:min-h-[11.8rem]">
                <div className="flex flex-col lg:flex-row items-center justify-between">
                  <div className="mb-4 lg:mb-0 flex flex-row items-center">
                    <h2 className="inline-block text-2xl sm:text-3xl lg:text-4xl font-semibold mr-1">
                      {collection.name}
                    </h2>
                    {collection.curated === true ? (
                      <VerifyIcon
                        iconClass="w-7 h-7"
                        className="m-1"
                      />
                    ) : null}
                    <CollectionOnchain
                      tags={collection.tags}
                      blend={true}
                    />
                  </div>
                  <div className="">
                    <Button
                      variant="outline"
                      className="dark:text-white dark:border-white dark:hover:bg-primary-6000 dark:hover:text-white dark:hover:border-slate-600"
                      color="dark"
                      radius="md"
                      size="xs"
                      onClick={copyToClipboard}
                    >
                      Policy ID:{" "}
                      {`${collection.policy.substring(
                        0,
                        5
                      )}...${collection.policy.substring(
                        collection.policy.length - 5
                      )}`}{" "}
                      <i className="las la-copy ml-2"></i>
                    </Button>
                  </div>
                </div>
                <div>
                  <span className="block mt-4 text-sm text-neutral-500 dark:text-neutral-400 max-w-lg">
                    {showFullDescription
                      ? collection?.description || ""
                      : `${
                          collection?.description?.slice(
                            0,
                            450
                          ) || ""
                        }`}
                  </span>
                  {collection?.description && (
                    <button
                      onClick={toggleDescription}
                      className="text-primary-500 mt-2 cursor-pointer text-sm"
                    >
                      {showFullDescription
                        ? "Show less"
                        : "Show more"}
                    </button>
                  )}
                </div>
              </div>
              <div className="mt-3 xl:mt-5 grid grid-cols-2 lg:grid-cols-5 gap-2 sm:gap-4 xl:gap-6">
                {/* -----Items ----- */}
                <div className="col-span-3 flex flex-col justify-end">
                  <div className="flex flex-row">
                    <span className="text-sm  text-base ">
                      Created By
                    </span>
                    <span className="text-sm ml-1 font-medium text-base  ">
                      {collection.owner}
                    </span>
                    <VerifyIcon iconClass="w-5 h-5" />
                  </div>
                  <div className="flex flex-row mt-4 md:mt-4 content-center justify-center md:content-start md:justify-start mb-4 md:mb-0">
                    <Tooltip content="Coming Soon">
                      <ButtonPrimary className="disabled">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 
                          22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2ZM16 12.75H12.75V16C12.75 
                          16.41 12.41 16.75 12 16.75C11.59 16.75 11.25 16.41 11.25 16V12.75H8C7.59 12.75 7.25 
                          12.41 7.25 12C7.25 11.59 7.59 11.25 8 11.25H11.25V8C11.25 7.59 11.59 7.25 12 
                          7.25C12.41 7.25 12.75 7.59 12.75 8V11.25H16C16.41 11.25 16.75 11.59 16.75 12C16.75 
                          12.41 16.41 12.75 16 12.75Z"
                            fill="#FFFFFF"
                          />
                        </svg>
                        <span className="ml-2">Follow</span>
                      </ButtonPrimary>
                    </Tooltip>
                    {collection.ownerId === null ||
                    collection.ownerId === "unclaimed" ? (
                      <Tooltip content="Collection is unclaimed">
                        <ButtonSecondary
                          onClick={() =>
                            handleArtistClick(
                              collection.ownerId ?? ""
                            )
                          }
                          className="ml-2 fill-black dark:fill-white"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill=""
                          >
                            <ellipse
                              cx="12"
                              cy="8"
                              rx="5"
                              ry="6"
                            />
                            <path d="M21.8,19.1c-0.9-1.8-2.6-3.3-4.8-4.2c-0.6-0.2-1.3-0.2-1.8,0.1c-1,0.6-2,0.9-3.2,0.9s-2.2-0.3-3.2-0.9    C8.3,14.8,7.6,14.7,7,15c-2.2,0.9-3.9,2.4-4.8,4.2C1.5,20.5,2.6,22,4.1,22h15.8C21.4,22,22.5,20.5,21.8,19.1z" />
                          </svg>
                          <span className="ml-1">
                            Artist Profile
                          </span>
                        </ButtonSecondary>
                      </Tooltip>
                    ) : (
                      <ButtonSecondary
                        onClick={() =>
                          handleArtistClick(
                            collection.ownerId ?? ""
                          )
                        }
                        className="ml-2 fill-black dark:fill-white"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill=""
                        >
                          <ellipse cx="12" cy="8" rx="5" ry="6" />
                          <path d="M21.8,19.1c-0.9-1.8-2.6-3.3-4.8-4.2c-0.6-0.2-1.3-0.2-1.8,0.1c-1,0.6-2,0.9-3.2,0.9s-2.2-0.3-3.2-0.9    C8.3,14.8,7.6,14.7,7,15c-2.2,0.9-3.9,2.4-4.8,4.2C1.5,20.5,2.6,22,4.1,22h15.8C21.4,22,22.5,20.5,21.8,19.1z" />
                        </svg>
                        <span className="ml-1">
                          Artist Profile
                        </span>
                      </ButtonSecondary>
                    )}
                  </div>
                </div>
                {/* ----- 1 ----- */}
                <div className="rounded-2xl flex flex-col items-center justify-center shadow-md border border-neutral-50 dark:border-neutral-800 p-5 lg:p-6">
                  <span className="text-sm text-neutral-500 dark:text-neutral-400">
                    Floor Price
                  </span>
                  <span className="font-medium text-base mt-4 sm:text-xl sm:mt-6">
                    {collection.floorPrice
                      ? Math.round(collection.floorPrice)
                      : "N/A"}
                  </span>
                  <span className="text-xs text-neutral-500 dark:text-neutral-400 mt-1">
                    ADA
                  </span>
                </div>
                {/* -----Items ----- */}
                <div className="rounded-2xl flex flex-col items-center justify-center shadow-md border border-neutral-50 dark:border-neutral-800 p-5 lg:p-6">
                  <span className="text-sm text-neutral-500 dark:text-neutral-400">
                    Items
                  </span>
                  <span className="font-medium text-base mt-4 sm:text-xl sm:mt-6">
                    {collection?.size ??
                      data?.pages.reduce(
                        (total, page) => total + page.length,
                        0
                      ) ??
                      0}
                  </span>
                  <span className="text-xs text-neutral-500 dark:text-neutral-400 mt-1">
                    total
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ====================== END HEADER ====================== */}
      <div
        className="container py-16 lg:pb-28 lg:pt-5 space-y-10 lg:space-y-18"
        ref={containerRef}
      >
        <div
          className={`sticky top-0 z-30 mt-8 p-3 lg:p-3 bg-white dark:bg-neutral-900`}
        >
          <div>
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-y-10 lg:gap-y-0">
              <div className="order-3 lg:order-1">
                <SideFilters
                  filter={filterSchema?.metadata || {}}
                  onFilterChange={handleMetadataFilterChange}
                  applyFilter={applyFilters}
                  resetFilter={resetFilters}
                />
              </div>

              <div className="text-lg lg:text-2xl order-1 lg:order-2 lg:pt-1 lg:pl-24 lg:text-center text-center align-text-bottom align-baseline font-bold leading-none tracking-tight text-gray-900 dark:text-white">
                <div className="text-xs lg:-mb-2">The</div>
                <a
                  className={
                    " " +
                    (openTab === 1
                      ? "underline underline-offset-3 decoration-3 decoration-gray-500"
                      : "")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    handleTabClick(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  Collection
                </a>
              </div>
              <div className="text-lg lg:text-2xl order-2 lg:pt-1 lg:pr-24 text-center font-bold leading-none tracking-tight text-gray-900 dark:text-white">
                <div className="text-xs lg:-mb-2">View</div>
                <a
                  className={
                    " " +
                    (openTab === 2
                      ? "underline underline-offset-3 decoration-3 decoration-gray-500"
                      : "")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    handleTabClick(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  Details
                </a>
              </div>
              <div className="block flex-shrink-0 text-right order-4 lg:order-4">
                <ButtonOutline
                  className="w-auto !pr-16"
                  sizeClass="pl-4 py-2.5 sm:pl-6"
                  onClick={() => {
                    setIsOpen(!isOpen);
                  }}
                >
                  <svg
                    className={`w-4 h-4 sm:w-6 sm:h-6`}
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M14.3201 19.07C14.3201 19.68 13.92 20.48 13.41 20.79L12.0001 21.7C10.6901 22.51 8.87006 21.6 8.87006 19.98V14.63C8.87006 13.92 8.47006 13.01 8.06006 12.51L4.22003 8.47C3.71003 7.96 3.31006 7.06001 3.31006 6.45001V4.13C3.31006 2.92 4.22008 2.01001 5.33008 2.01001H18.67C19.78 2.01001 20.6901 2.92 20.6901 4.03V6.25C20.6901 7.06 20.1801 8.07001 19.6801 8.57001"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.07 16.52C17.8373 16.52 19.27 15.0873 19.27 13.32C19.27 11.5527 17.8373 10.12 16.07 10.12C14.3027 10.12 12.87 11.5527 12.87 13.32C12.87 15.0873 14.3027 16.52 16.07 16.52Z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M19.87 17.12L18.87 16.12"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>

                  <span className="block truncate ml-2.5">
                    Filters
                  </span>
                  <span className="absolute top-1/2 -translate-y-1/2 right-5">
                    <ChevronDownIcon
                      className={`hidden md:block w-4 h-4 sm:w-5 sm:h-5 ${
                        isOpen ? "rotate-180" : ""
                      }`}
                      aria-hidden="true"
                    />
                  </span>
                </ButtonOutline>
              </div>
            </div>
          </div>
          <Transition
            show={isOpen}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-150"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="w-full border-b border-neutral-200/70 dark:border-neutral-700 my-4 hidden md:block"></div>
            <TabFilters
              searchQuery={searchInput}
              onSearchQueryChange={handleSearchQueryChange}
              onPriceFilterChange={handlePriceFilterChange}
              selectedPriceFilterRange={
                priceRange as [number, number]
              }
              maxPriceFilterRange={[0, Infinity]}
              onSortOrderChange={handleSortOrderChange}
              isOpenMobileFilter={isOpen}
              closeModal={() => setIsOpen(false)}
            />
          </Transition>
        </div>
        <div
          className={`grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10  ${
            openTab === 1 ? "block" : "hidden"
          }`}
        >
          {data
            ? data.pages.map((page, pageIndex) => (
                <React.Fragment key={pageIndex}>
                  {page.map((asset, assetIndex) => (
                    <CardNFT
                      key={assetIndex}
                      id={asset.metadata.id}
                      _id={asset._id}
                      path={path}
                      name={asset.metadata.name}
                      policy={collection.policy}
                      image={
                        Array.isArray(asset.metadata.image)
                          ? asset.metadata.image.join("")
                          : asset.metadata.image
                      }
                      src={
                        asset.metadata.files &&
                        asset.metadata.files.length > 0
                          ? asset.metadata.files[0]?.src
                          : asset.metadata.image
                      }
                      price={
                        pricingData?.[asset.asset]?.price ?? 0
                      }
                      isListed={
                        pricingData?.[asset.asset] !== undefined
                      }
                      isLiked={!!likedMap?.[asset._id]}
                      likes={asset.likes || 0}
                      onIsLikedChange={handleOnIsLikedChange}
                      className="fade-in"
                    />
                  ))}
                </React.Fragment>
              ))
            : Array.from(Array(12)).map((_, index) => (
                <CardNFTSkeleton key={index} />
              ))}
        </div>
        <div
          ref={ref}
          className={`grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10 ${
            openTab === 1 ? "block" : "hidden"
          }`}
        >
          {isFetchingNextPage &&
            Array.from(Array(24)).map((_, index) => (
              <CardNFTSkeleton key={index} />
            ))}
          {!hasNextPage && <div>No more assets to load.</div>}
        </div>
        {/* TAB 2 - COLLECTION DETAILS */}
        <div
          className={openTab === 2 ? "block" : "hidden"}
          id="link2"
        >
          <div className="flex flex-wrap">
            {/* Sidebar */}
            <div className="lg:w-3/12 lg:pr-5 w-full lg:block dark:bg-neutral-900">
              <div className="p-3 dark:bg-neutral-800 bg-gray-100 flex flex-wrap rounded">
                <div className="lg:w-full lg:mx-0 mx-3 mb-6 ">
                  <h2 className="text-sm font-semibold">
                    Artist Site
                  </h2>
                  <p className="text-xs mt-2">
                    {collection.artistpath ? (
                      <a
                        href={"https://" + collection.artistpath}
                        target="_blank"
                        rel="noreferrer"
                      >
                        https://{collection.artistpath}
                      </a>
                    ) : (
                      <span>Not provided.</span>
                    )}
                    {collection.artistpath && (
                      <i className="las la-external-link-alt ml-2"></i>
                    )}
                  </p>
                </div>
                <div className="lg:w-full lg:mx-0 mx-3 mb-6 ">
                  <h2 className="text-sm font-semibold">
                    Project Tags
                  </h2>
                  <p className="text-sm mt-2">
                    {Array.isArray(collection.customTags) &&
                    collection.customTags.length > 0 ? (
                      collection.customTags.map(
                        (customTag, index) => (
                          <Badge
                            key={index}
                            className="mx-1 my-1"
                            variant="outline"
                          >
                            {customTag}
                          </Badge>
                        )
                      )
                    ) : (
                      <span className="text-xs">
                        No custom tags available.
                      </span>
                    )}
                  </p>
                </div>
                <div className=" lg:w-1/2 lg:mx-0 mx-3 mb-6 lg:mb-0 lg:pr-2">
                  <h2 className="text-sm font-semibold">
                    Library
                  </h2>
                  <p className="text-xs mt-2">
                    {collection.library ? (
                      collection.library
                    ) : (
                      <span>Unknown</span>
                    )}
                  </p>
                </div>
                <div className=" lg:w-1/2 lg:mx-0 mx-3 pl-2 lg:mb-6">
                  <h2 className="text-sm font-semibold">
                    Language
                  </h2>
                  <p className="text-xs mt-2">
                    {collection.language ? (
                      collection.language
                    ) : (
                      <span>Unknown</span>
                    )}
                  </p>
                </div>
                <div className="lg:w-full lg:mx-0 mx-3 mb-6">
                  <h2 className="text-sm font-semibold ">
                    Cardanoscan
                  </h2>
                  <p className="text-xs mt-2">
                    <a
                      href={`https://cardanoscan.io/tokenPolicy/${collection.policy}`}
                    >
                      View Contract
                      <i className="las la-external-link-alt ml-2"></i>
                    </a>
                  </p>
                </div>
                <div className="lg:w-full lg:mx-0 mx-3 mb-6">
                  <h2 className="text-sm font-semibold">
                    Display Notes
                  </h2>
                  <p className="text-xs mt-2">
                    {collection.notes ? (
                      collection.notes
                    ) : (
                      <span>Not provided.</span>
                    )}
                  </p>
                </div>
                <div className="lg:w-full lg:mx-0 mx-3 mb-6">
                  <h2 className="text-sm font-semibold">
                    Creative Credits
                  </h2>
                  <p className="text-xs mt-2">
                    {collection.credits ? (
                      collection.credits
                    ) : (
                      <span>Not provided.</span>
                    )}
                  </p>
                </div>
                <div className="lg:w-full lg:mx-0 mx-3 mb-6">
                  <h2 className="text-sm font-semibold">
                    License
                  </h2>
                  <p className="text-xs mt-2">
                    {collection.license ? (
                      collection.license
                    ) : (
                      <span>Undisclosed License</span>
                    )}
                  </p>
                </div>
              </div>
            </div>
            {/* Main Content */}
            <div className="lg:w-9/12 w-full">
              <RichTextEditor
                editor={editor}
                className="border-0 p-0"
              >
                <RichTextEditor.Content />
              </RichTextEditor>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageCollection;
