import React, { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import VerifyIcon from "./VerifyIcon";

import { Collection } from "../helpers/types";
import CollectionTags from "./CollectionTags";
import CollectionOnchain from "./CollectionOnchain";

import { Icon } from "containers/DiscoverPage/Card";
import Card from "containers/DiscoverPage/Card";

const CollectionProject: FC<Collection> = ({
  className,
  id,
  name,
  cardpath,
  cardtype,
  floorPrice,
  owner,
  curated,
  avatarpath,
  tags,
  description,
  path,
}) => {
  const truncatedDescription =
    description && description.length > 75
      ? description.slice(0, 75) + ".."
      : description;

  return (
    <div className="nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] fade-in">
      <Link to={`/collection/${path}`} className="">
        <div className="flex flex-col items-start  relative">
          <Card
            cardpath={cardpath || ""}
            name={name}
            imageSize="large"
          />
          <div className="flex flex-col justify-center absolute right-3 top-[21.2rem] bg-white dark:bg-neutral-900 rounded p-1 dark:shadow dark:shadow-neutral-900">
            <span className="flex items-center text-xs">
              <span className="font-light italic mr-1">by </span>
              <span className="font-medium text-md">{owner}</span>
              {curated === true ? (
                <VerifyIcon iconClass="w-4 h-4 " />
              ) : (
                " "
              )}
            </span>
          </div>
          {/*<div className="flex flex-col justify-center absolute left-3 top-[21.2rem] bg-white dark:bg-neutral-900 rounded p-1 dark:shadow dark:shadow-neutral-900">
            <span className="text-xs">
              <span className="font-medium text-md">2021</span>
            </span>
          </div>*/}
          <div className="flex items-center justify-center absolute top-2 right-2 transform -translate-x-1/2 bg-white dark:bg-neutral-900 rounded p-1 shadow dark:shadow dark:shadow-neutral-900">
            <CollectionOnchain
              tags={tags}
              blend={true}
              size="small"
            />
          </div>
          <p className="dark:text-white text-black mt-4 text-sm font-light px-2 min-h-[2.6rem]">
            {truncatedDescription}
          </p>
          <p className="text-sm border font-light dark:border-white/[0.2] border-black/[0.2] rounded-full mt-4 text-black dark:text-white mx-2 px-2 py-0.5">
            Floor Price:{" "}
            {floorPrice ? Math.round(floorPrice) : ""} ADA
          </p>
          <div className="flex flex-wrap justify-end pt-2 pb-3 cursor-pointer px-2">
            <CollectionTags tags={tags} />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default CollectionProject;
