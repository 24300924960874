import React, { FC, useCallback, useState } from "react";
import {
  useQuery,
  useMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { Helmet } from "react-helmet";
import {
  useParams,
  useNavigate,
  useLocation,
} from "react-router-dom";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import NcImage from "shared/NcImage/NcImage";
import CollectionCard from "components/CollectionCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NftMoreDropdown from "components/NftMoreDropdown";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SocialsList from "shared/SocialsList/SocialsList";
import FollowButton from "components/FollowButton";
import VerifyIcon from "components/VerifyIcon";
import { Tab } from "@headlessui/react";
import CardAuthorBox4 from "components/CardAuthorBox4/CardAuthorBox4";
import ArchiveFilterListBox from "components/ArchiveFilterListBox";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import {
  fetchUserByName,
  listAllLikedAssets,
  listAllUserLikedAssets,
} from "helpers/helpers";
import {
  createLikeOnAsset,
  deleteLikeOnAsset,
  fetchCollectionLikes,
} from "helpers/helpers";
import { Tooltip } from "flowbite-react";
import { selectUser } from "app/auth/login/reducer";
import { useAppSelector } from "app/hooks";
import LoadingSpinner from "components/LoadingSpinner";
import { Toast } from "flowbite-react";
import { BadgeCheckIcon } from "@heroicons/react/outline";
import { Tabs } from "../../components/Ui/Tabs";
import CardNFT from "components/CardNFT";
import { useUserData } from "hooks/useUserData";
import {
  Asset,
  AssetData,
  CollectionFavorites,
} from "helpers/types";
import discord from "images/socials/discord.svg";
import twitter from "images/socials/twitter.svg";

export interface ProfilePageProps {
  className?: string;
}

interface PendingLike {
  assetId: string;
  isLiked: boolean;
}

interface LikedAssetsMap {
  [policy: string]: CollectionFavorites;
}

const fetchLikedAssets = async (userId: string | undefined) => {
  if (!userId) return null;
  try {
    const result = await listAllUserLikedAssets(userId);
    return result as LikedAssetsMap;
  } catch (error) {
    console.error("Error fetching liked assets:", error);
    return null;
  }
};

const useLikedAssets = (userId: string | undefined) => {
  return useQuery({
    queryKey: ["likedAssets", userId],
    queryFn: () => fetchLikedAssets(userId),
    enabled: !!userId,
  });
};

const useLikeMutation = (
  userData: { id: string } | null | undefined
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      isLiked,
      assetId,
      policy,
    }: {
      isLiked: boolean;
      assetId: string;
      policy: string;
    }) =>
      isLiked
        ? createLikeOnAsset(policy, assetId)
        : deleteLikeOnAsset(policy, assetId),
    onSuccess: (_, { isLiked, assetId, policy }) => {
      queryClient.setQueryData(
        ["likedMap", policy],
        (prevLikedMap: any) => ({
          ...prevLikedMap,
          [assetId]: isLiked,
        })
      );
      queryClient.invalidateQueries({
        queryKey: ["assets", policy],
      });
      queryClient.invalidateQueries({
        queryKey: ["likedAssets", userData?.id],
      });
    },
  });
};

const ProfilePage: FC<ProfilePageProps> = ({
  className = "",
}) => {
  const { name: userName } = useParams<{
    name: string;
    t: string;
  }>();
  const [isUser, setIsUser] = useState<boolean>(false);
  const [isArtist, setIsArtist] = useState<boolean>(false);
  const userSessionDetails = useAppSelector(selectUser);
  const [showToast, setShowToast] = useState<boolean>(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const toast = searchParams.get("t");
  const {
    data: userData,
    isLoading,
    error,
    refetch,
  } = useUserData(userName);
  const {
    data: likedAssets,
    isLoading: isLoadingLikedAssets,
    error: likedAssetsError,
  } = useLikedAssets(userData?.id);
  const likeMutation = useLikeMutation(userData);
  const queryClient = useQueryClient();

  React.useEffect(() => {
    if (toast === "profileUpdated") {
      refetch();
    }
  }, [toast, refetch]);

  React.useEffect(() => {
    if (userData && userSessionDetails) {
      setIsUser(userData.id === userSessionDetails.id);
    }
  }, [userData, userSessionDetails]);

  const handleOnIsLikedChange = useCallback(
    (isLiked: boolean, assetId: string, policy: string) => {
      if (!userSessionDetails) return;
      likeMutation.mutate(
        { isLiked, assetId, policy },
        {
          onError: (error: unknown) => {
            console.error("Error updating like status:", error);
            // You might want to show an error message to the user here
          },
          onSuccess: () => {
            // Handle successful like/unlike here if needed
          },
        }
      );
    },
    [userSessionDetails, likeMutation]
  );

  const getSocialsList = useCallback(() => {
    const socialsList = [];
    if (userData?.discord) {
      socialsList.push({
        name: "Discord",
        icon: discord,
        href: userData.discord,
      });
    }
    if (userData?.twitter) {
      socialsList.push({
        name: "Twitter",
        icon: twitter,
        href: userData.twitter,
      });
    }
    return socialsList;
  }, [userData]);

  const tabs = [
    {
      title: "Showcase",
      value: "showcase",
      content: (
        <div className="w-full h-full rounded-2xl flex flex-col items-center justify-center p-10 text-black dark:text-white bg-gradient-to-b dark:from-neutral-800 dark:to-neutral-900 from-neutral-100 to-white">
          <span className="text-xl md:text-4xl font-bold mb-4">
            Showcase Your On-chain NFTs
          </span>
          <p>Feature coming soon.</p>
        </div>
      ),
    },
    {
      title: "Liked",
      value: "liked",
      content: (
        <div className="w-full overflow-hidden relative h-full rounded-2xl p-10 text-black dark:text-white bg-gradient-to-b dark:from-neutral-800 dark:to-neutral-900 from-neutral-100 to-white ">
          <span className="text-xl md:text-4xl font-bold">
            Liked On-chain NFTs
          </span>
          {isLoadingLikedAssets && <p>Loading liked assets...</p>}
          {likedAssetsError && (
            <p>
              Error loading liked assets:{" "}
              {(likedAssetsError as Error).message}
            </p>
          )}
          {likedAssets &&
            Object.entries(likedAssets).length === 0 && (
              <p>No liked assets found.</p>
            )}
          {likedAssets &&
            Object.entries(likedAssets).map(
              ([policy, collectionFavorites]) => (
                <React.Fragment key={policy}>
                  <h2 className="text-xl my-4">
                    {collectionFavorites.name}
                  </h2>
                  <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-4 lg:mt-6">
                    {collectionFavorites.assets.map((asset) => (
                      <CardNFT
                        key={`${policy}-${asset._id}-${asset.likes}`}
                        id={asset.metadata?.id || asset._id}
                        _id={asset._id}
                        image={
                          Array.isArray(asset.metadata?.image)
                            ? asset.metadata.image.join("")
                            : asset.metadata?.image
                        }
                        name={
                          asset.metadata?.name ||
                          asset.metadata?.title ||
                          asset.name ||
                          "Unnamed Asset"
                        }
                        dbname={asset.name}
                        src={
                          asset.metadata?.files &&
                          asset.metadata.files.length > 0
                            ? asset.metadata.files[0]?.src
                            : asset.metadata?.image
                        }
                        path={collectionFavorites.path}
                        policy={policy}
                        price={0}
                        isListed={false}
                        className="fade-in"
                        isLiked={!!userSessionDetails}
                        likes={asset.likes || 0}
                        onIsLikedChange={(isLiked) =>
                          handleOnIsLikedChange(
                            isLiked,
                            asset._id,
                            policy
                          )
                        }
                      />
                    ))}
                  </div>
                </React.Fragment>
              )
            )}
        </div>
      ),
      onSetActive: async () => {
        if (userData && userData.id) {
          await queryClient.refetchQueries({
            queryKey: ["likedAssets", userData.id],
          });
        }
      },
    },
    {
      title: "Badges",
      value: "badges",
      content: (
        <div className="w-full overflow-hidden relative h-full rounded-2xl p-10  text-black dark:text-white bg-gradient-to-b dark:from-neutral-800 dark:to-neutral-900 from-neutral-100 to-white ">
          <span className="text-xl md:text-4xl font-bold">
            Acquire Badges and Rewards using the platform
          </span>
          <p>Feature coming soon.</p>
        </div>
      ),
    },
    {
      title: "Followers",
      value: "followers",
      content: (
        <div className="w-full overflow-hidden relative h-full rounded-2xl p-10  text-black dark:text-white bg-gradient-to-b dark:from-neutral-800 dark:to-neutral-900 from-neutral-100 to-white ">
          <span className="text-xl md:text-4xl font-bold">
            Follow Your Favorite Artists
          </span>
          <p>Feature coming soon.</p>
        </div>
      ),
    },
    {
      title: "Following",
      value: "following",
      content: (
        <div className="w-full overflow-hidden relative h-full rounded-2xl p-10  text-black dark:text-white bg-gradient-to-b dark:from-neutral-800 dark:to-neutral-900 from-neutral-100 to-white ">
          <span className="text-xl md:text-4xl font-bold">
            Follow Your Favorite Artists
          </span>
          <p>Feature coming soon.</p>
        </div>
      ),
    },
  ];

  if (isArtist) {
    tabs.unshift({
      title: "Collections",
      value: "collections",
      content: (
        <div className="w-full overflow-hidden relative h-full rounded-2xl p-10 text-xl md:text-4xl font-bold text-black dark:text-white bg-gradient-to-b dark:from-neutral-800 dark:to-neutral-900 from-neutral-100 to-white ">
          <p>Collections</p>
          <p>Tab content</p>
        </div>
      ),
    });
  }

  React.useEffect(() => {
    if (toast === "profileUpdated") {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 5000);
    }
  }, [toast]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return <div>Error: {(error as Error).message}</div>;
  } else {
    return (
      <div
        className={`nc-AuthorPage  ${className}`}
        data-nc-id="AuthorPage"
      >
        <Helmet>
          <title>
            Cardano Art - {userData?.displayName || ""}'s Profile
          </title>
        </Helmet>

        {/* HEADER */}
        <div className="w-full">
          <div className="relative w-full h-40 md:h-60 2xl:h-72">
            <NcImage
              containerClassName="absolute inset-0"
              src={userData?.bannerImage}
              className="object-cover w-full h-full"
            />
          </div>
          <div className="container -mt-10 lg:-mt-16">
            <div className="relative bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 p-5 lg:p-8 rounded-3xl md:rounded-[40px] shadow-xl flex flex-col md:flex-row">
              <div className="w-32 lg:w-44 flex-shrink-0 mt-12 sm:mt-0">
                <NcImage
                  src={userData?.avatarImage}
                  containerClassName="aspect-w-1 aspect-h-1 rounded-3xl overflow-hidden"
                />
              </div>
              <div className="pt-5 md:pt-1 md:ml-6 xl:ml-14 flex-grow">
                <div className="max-w-screen-sm ">
                  <h2 className="inline-flex items-center text-lg sm:text-3xl lg:text-4xl font-semibold">
                    {userData?.displayName
                      ? userData.displayName.length > 25
                        ? userData.displayName.substring(0, 25) +
                          "..."
                        : userData.displayName
                      : ""}
                    <VerifyIcon
                      className="ml-2"
                      iconClass="w-6 h-6 sm:w-7 sm:h-7 xl:w-8 xl:h-8"
                    />
                  </h2>
                  <h3>
                    @
                    {userData?.name
                      ? userData.name.length > 25
                        ? userData.name.substring(0, 25) + "..."
                        : userData.name
                      : ""}
                  </h3>
                  <span className="block mt-4 text-sm text-neutral-500 dark:text-neutral-400">
                    {userData?.description ||
                      "A proud member of the Cardano Art community."}
                  </span>
                </div>
                <div className="mt-4 ">
                  <SocialsList
                    itemClass="block w-7 h-7"
                    socials={getSocialsList()}
                  />
                </div>
              </div>
              <div className="absolute md:static left-5 top-4 sm:left-auto sm:top-5 sm:right-5 flex flex-row-reverse justify-end">
                <NftMoreDropdown
                  actions={[
                    {
                      id: "report",
                      name: "Report abuse",
                      icon: "las la-flag",
                    },
                    ...(isUser
                      ? [
                          {
                            id: "edit profile",
                            name: "Edit profile",
                            icon: "las la-cog",
                          },
                        ]
                      : []),
                  ]}
                  containerClassName="w-8 h-8 md:w-10 md:h-10 flex items-center justify-center rounded-full bg-neutral-100 hover:bg-neutral-200 dark:hover:bg-neutral-700 dark:bg-neutral-800 cursor-pointer"
                />
                <Tooltip content="Coming Soon" placement="top">
                  <FollowButton
                    isFollowing={false}
                    disabled={true}
                    onFollow={() => {}}
                    fontSize="text-sm md:text-base font-medium"
                    sizeClass="px-4 mx-4 py-1 md:py-2.5 h-8 md:!h-10 sm:px-6 lg:px-8"
                  />
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
        {/* ====================== END HEADER ====================== */}

        <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 lg:space-y-28">
          <main>
            <div className="max-w-7xl mx-auto w-full my-5">
              <Tabs tabs={tabs} />
            </div>
            <div className="fixed bottom-4 right-4 z-10">
              {showToast && (
                <Toast className="bg-green-500">
                  <div className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
                    <BadgeCheckIcon className="h-5 w-5" />
                  </div>
                  <div className="ml-3 text-sm font-normal text-white px-3">
                    Profile successfully updated.
                  </div>
                  <Toast.Toggle />
                </Toast>
              )}
            </div>
          </main>

          {/* === SECTION 5 === 
          <div className="relative py-16 lg:py-28 mt-48">
            <BackgroundSection />
            <SectionGridAuthorBox data={Array.from("11111111")} boxCard="box4" />
          </div>
          */}
        </div>
      </div>
    );
  }
};

export default ProfilePage;
